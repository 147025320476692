<template>
  <div class="a-media__wrapper">
    <template v-if="videoUrl || video">
      <a-video
        :video="video"
        :src="videoUrl"
        :aspect-ratio="videoAspectRatio"
        :link="url"
        :rounded="rounded"
        @play="$emit('play', $event)"
        @pause="$emit('pause', $event)"
      />
    </template>
    <figure v-else-if="showCaption" class="content-data__image">
      <a-image
        :src="imageUrlWithDefault"
        :alt="alt"
        :aspect-ratio="imageAspectRatio"
        :container-aspect-ratio="imageContainerAspectRatio"
        :object-fit="objectFit"
        :url="url"
        :directives="directives"
        :open-in-new-tab="openInNewTab"
        :link-trailing-slash="linkTrailingSlash"
        :link-aria-label="linkAriaLabel"
        :image="image"
        :enlargeable="enlargeableImage"
        :srcset-location="srcsetLocation"
        :viewport-optimization="viewportOptimization"
        :force-url="forceUrl"
        :rounded="rounded"
        :debug="debug"
        @enlarge="$emit('enlarge', $event)"
        @click.native="$emit('click-on-image')"
      />

      <figcaption class="image-caption text-caption figure-img__c">
        {{ imageCaption }}
      </figcaption>
    </figure>
    <a-image
      v-else
      :src="imageUrlWithDefault"
      :alt="alt"
      :aspect-ratio="imageAspectRatio"
      :container-aspect-ratio="imageContainerAspectRatio"
      :object-fit="objectFit"
      :url="url"
      :directives="directives"
      :open-in-new-tab="openInNewTab"
      :link-trailing-slash="linkTrailingSlash"
      :image="image"
      :enlargeable="enlargeableImage"
      :srcset-location="srcsetLocation"
      :viewport-optimization="viewportOptimization"
      :force-url="forceUrl"
      :rounded="rounded"
      :debug="debug"
      @enlarge="$emit('enlarge', $event)"
      @click.native="$emit('click-on-image')"
    />
  </div>
</template>

<script>
import mixins from '@/utils/mixins'
import AVideo from 'shared/AVideo'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import { REL_ATTRIBUTE_BACKEND_ENUM } from '@fmpedia/enums'

export { IMAGE_SIZE } from 'enums/images'

const IMAGE_OBJECT_REMAP_SETTINGS = {
  URL: 'url',
  uRL: 'url',
  Caption: 'caption',
  AltText: 'altText'
}

export default {
  name: 'AMedia',
  mixins: [mixins.urlFormatters],
  components: { AVideo },
  props: {
    image: propValidator([PROP_TYPES.OBJECT], false),
    imageUrl: propValidator([PROP_TYPES.STRING], false),
    video: propValidator([PROP_TYPES.OBJECT], false),
    videoUrl: propValidator([PROP_TYPES.STRING], false),
    alt: propValidator([PROP_TYPES.STRING], false),
    imageAspectRatio: propValidator([PROP_TYPES.NUMBER], false, null),
    imageContainerAspectRatio: propValidator(
      [PROP_TYPES.NUMBER, PROP_TYPES.STRING],
      false,
      null
    ),
    videoAspectRatio: propValidator([PROP_TYPES.NUMBER], false, null),
    objectFit: propValidator([PROP_TYPES.STRING], false, 'contain'),
    url: propValidator([PROP_TYPES.STRING], false, ''),
    directives: propValidator(
      [PROP_TYPES.STRING],
      false,
      REL_ATTRIBUTE_BACKEND_ENUM.NOFOLLOW
    ),
    openInNewTab: propValidator([PROP_TYPES.BOOLEAN], false, false),
    showCaption: propValidator([PROP_TYPES.BOOLEAN], false, false),
    linkTrailingSlash: propValidator([PROP_TYPES.BOOLEAN], false),
    enlargeableImage: propValidator(
      [PROP_TYPES.BOOLEAN, PROP_TYPES.STRING],
      false,
      false
    ),
    srcsetLocation: propValidator([PROP_TYPES.STRING], false, null),
    viewportOptimization: propValidator([PROP_TYPES.BOOLEAN], false, false),
    debug: propValidator([PROP_TYPES.BOOLEAN], false, false),
    forceUrl: propValidator([PROP_TYPES.BOOLEAN], false, false),
    linkAriaLabel: propValidator([PROP_TYPES.STRING], false),
    rounded: propValidator([PROP_TYPES.BOOLEAN], false, false)
  },
  computed: {
    formattedImage() {
      if (!this.image) return this.image

      return this.$helper.remapObjectKeys(
        this.image,
        IMAGE_OBJECT_REMAP_SETTINGS
      )
    },
    imageUrlWithDefault() {
      return (
        this.formattedImage?.url ||
        this.imageUrl ||
        this.$helper.getLinkToFmBucketFile('article-default-image.jpg')
      )
    },
    imageCaption() {
      if (!this.formattedImage) return ''

      return this.formattedImage?.caption || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.a-media__wrapper {
  width: 100%;
}

.image-caption {
  margin-top: 10px;
}
</style>
