<template>
  <div>
    <template v-if="!alwaysMobile">
      <template v-for="settings in visibilitySettings">
        <a-visibility
          show
          v-bind="settings.visibilityProps"
          :key="settings.breakpoint"
        >
          <a-dots
            :text="text"
            :tag="tag"
            :link-props="linkProps"
            :row-count="settings.rowCount"
            :row-height="settings.rowHeight"
            :fixed-height="settings.fixedHeight"
            :style="getDynamicStylesByRowHeight(settings.rowHeight)"
          />
        </a-visibility>
      </template>
    </template>
    <a-dots
      v-else-if="visibilitySettingsAppliedOnMobile"
      :text="text"
      :tag="tag"
      :link-props="linkProps"
      :row-count="visibilitySettingsAppliedOnMobile.rowCount"
      :row-height="visibilitySettingsAppliedOnMobile.rowHeight"
      :fixed-height="visibilitySettingsAppliedOnMobile.fixedHeight"
      :style="
        getDynamicStylesByRowHeight(visibilitySettingsAppliedOnMobile.rowHeight)
      "
    />
  </div>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { BREAKPOINT_RENDER_ORDERING, Breakpoint } from '@/utils/scss'
import { hydrateWhenVisible } from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'

export function configADots(rowCount, rowHeight, fixedHeight) {
  return { rowCount, rowHeight, fixedHeight }
}

export default {
  name: 'ADotsResponsive',
  components: {
    ADots: hydrateWhenVisible(() => import('shared/ADots'), {
      props: [
        'text',
        'tag',
        'link-props',
        'row-count',
        'row-height',
        'fixed-height'
      ]
    })
  },
  props: {
    text: propValidator([PROP_TYPES.STRING]),
    tag: propValidator([PROP_TYPES.STRING], false),
    linkProps: propValidator([PROP_TYPES.OBJECT], false),
    settings: propValidator([PROP_TYPES.OBJECT]),
    /**
     * We use this prop for AMP pages to use mobile settings on all breakpoints.
     */
    alwaysMobile: propValidator([PROP_TYPES.BOOLEAN], false, false)
  },
  computed: {
    visibilitySettings() {
      return BREAKPOINT_RENDER_ORDERING.reduce((acc, breakpointName) => {
        const breakpoint = new Breakpoint({ name: breakpointName })
        const breakpointSettings = this.settings[breakpoint.value]

        if (!breakpointSettings) return acc

        const nextBreakpointFromSettings = this.getNextBreakpointFromSettings(
          breakpoint
        )
        const baseReturn = {
          breakpoint: breakpoint.value,
          ...breakpointSettings
        }

        if (!nextBreakpointFromSettings && !breakpoint.isLowest) {
          return [
            ...acc,
            {
              ...baseReturn,
              visibilityProps: { to: [breakpoint.value] }
            }
          ]
        }

        if (
          breakpoint.isLowest ||
          breakpoint.next?.value === nextBreakpointFromSettings?.value
        ) {
          return [
            ...acc,
            {
              ...baseReturn,
              visibilityProps: { on: [breakpoint.value] }
            }
          ]
        }

        return [
          ...acc,
          {
            ...baseReturn,
            visibilityProps: {
              from: nextBreakpointFromSettings.previous.value,
              to: breakpoint.value
            }
          }
        ]
      }, [])
    },
    visibilitySettingsAppliedOnMobile() {
      const mobileBreakpoint = new Breakpoint({
        value: this.$breakpoint.mobile
      })
      const mobileSettings = this.settings[mobileBreakpoint.value]
      if (mobileSettings) {
        return mobileSettings
      }

      const previousBreakpointFromSettings = this.getPreviousBreakpointFromSettings(
        mobileBreakpoint
      )

      return previousBreakpointFromSettings
        ? this.settings[previousBreakpointFromSettings.value]
        : null
    }
  },
  methods: {
    getDynamicStylesByRowHeight(rowHeight) {
      return {
        lineHeight: `${rowHeight}px`
      }
    },
    getNextBreakpointFromSettings(breakpoint) {
      let nextBreakpoint = breakpoint.next
      let nextBreakpointSettings

      while (nextBreakpoint && !nextBreakpointSettings) {
        nextBreakpointSettings = this.settings[nextBreakpoint.value]
        if (!nextBreakpointSettings) {
          nextBreakpoint = nextBreakpoint.next
        }
      }

      return nextBreakpointSettings ? nextBreakpoint : null
    },
    getPreviousBreakpointFromSettings(breakpoint) {
      let previousBreakpoint = breakpoint.previous
      let previousBreakpointSettings

      while (previousBreakpoint && !previousBreakpointSettings) {
        previousBreakpointSettings = this.settings[previousBreakpoint.value]
        if (!previousBreakpointSettings) {
          previousBreakpoint = previousBreakpoint.previous
        }
      }

      return previousBreakpointSettings ? previousBreakpoint : null
    }
  }
}
</script>
