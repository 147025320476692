import {
  FL_DEFAULT_IMAGE_BY_CATEGORY,
  FL_ARTICLE_DEFAULT_IMAGE
} from 'enums/aws-files'
import { getLinkToFmBucketFile } from '@/plugins/helper'

export const FL_ARTICLE_MEDIA_FIELD = {
  FEATURED_IMAGE: 'FeaturedImage',
  FEATURED_VIDEO: 'FeaturedVideo',
  THUMBNAIL_IMAGE: 'ThumbnailImage'
}

export function getFlImageByCategorySlug(categorySlug) {
  const defaultImage =
    FL_DEFAULT_IMAGE_BY_CATEGORY[categorySlug] ||
    FL_ARTICLE_DEFAULT_IMAGE.GENERAL

  return {
    MediaType: 'Image',
    URL: getLinkToFmBucketFile(defaultImage)
  }
}
