<template>
  <div class="forexlive-latest-news-article-slot__wrapper">
    <a-media
      :image="image"
      :image-aspect-ratio="$options.consts.aspectRatios.flArticle"
      :video="video"
      :video-aspect-ratio="$options.consts.aspectRatios.flArticle"
      :url="generateFlArticleUrl(article)"
      force-url
      :rounded="true"
      class="forexlive-latest-news-article-slot__featured-media"
    />
    <a-dots-responsive
      :text="topic"
      tag="h4"
      :link-props="{ to: generateFlArticleUrl(article) }"
      :settings="$options.consts.topicSettings"
      class="forexlive-latest-news-article-slot__title"
    />
    <time class="forexlive-latest-news-article-slot__timestamp">
      {{ publishDate }}
    </time>
  </div>
</template>

<script>
import { prop } from 'ramda'

import { PROP_TYPES, propValidator } from '@/utils/validators'
import mixins from '@/utils/mixins'
import AMedia from 'shared/AMedia'
import { aspectRatios } from 'enums/aspectRatios'
import ADotsResponsive, { configADots } from 'shared/ADotsResponsive'
import {
  FL_ARTICLE_MEDIA_FIELD,
  getFlImageByCategorySlug
} from '@/utils/helpers/flImages'
import scss from '@/utils/scss'

const { breakpoints } = scss

export default {
  name: 'HomepageForexliveLatestNewsArticleSlot',
  mixins: [mixins.urlGenerators],
  components: { ADotsResponsive, AMedia },
  props: {
    article: propValidator([PROP_TYPES.OBJECT])
  },
  consts: {
    aspectRatios,
    topicSettings: {
      [breakpoints.aboveDesktopXl]: configADots(3, 22, true),
      [breakpoints.desktopXl]: configADots(2, 22, true),
      [breakpoints.desktopLg]: configADots(3, 22, true),
      [breakpoints.tablet]: configADots(2, 22, true),
      [breakpoints.mobile]: configADots(3, 22, false)
    }
  },
  computed: {
    video() {
      return this.article[FL_ARTICLE_MEDIA_FIELD.FEATURED_VIDEO]
    },
    image() {
      const { CategorySlug } = this.article
      return (
        this.article[FL_ARTICLE_MEDIA_FIELD.FEATURED_IMAGE] ||
        this.article[FL_ARTICLE_MEDIA_FIELD.THUMBNAIL_IMAGE] || {
          ...getFlImageByCategorySlug(CategorySlug),
          AltText: 'ForexLive article'
        }
      )
    },
    topic() {
      return prop('Topic', this.article)
    },
    publishDate() {
      return this.$helper.formatDate.toDefault(this.article.PublishedOn)
    }
  },
  methods: { prop }
}
</script>

<style scoped lang="scss">
.forexlive-latest-news-article-slot__wrapper {
  .forexlive-latest-news-article-slot__title {
    $margin-top: (
      $above-desktop-xl: 15px
    );
    $font-size: (
      $above-desktop-xl: 20px,
      $mobile: 18px
    );

    @include setStyleByBreakpoint('margin-top', $margin-top);
    @include setStyleByBreakpoint('font-size', $font-size);

    line-height: 22px;
    font-weight: 600;
  }

  .forexlive-latest-news-article-slot__timestamp {
    margin-top: 15px;
    display: block;
    font-size: 12px;
  }
}
</style>
