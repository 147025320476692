<template>
  <amp-youtube-iframe
    v-if="isYoutubeAmpIframeVisible"
    :src="videoSource"
    :aspect-ratio="aspectRatio"
    :class="dynamicWrapperClass"
  />

  <div
    v-else
    v-observe-visibility="visibilityOptions"
    class="a-video__wrapper"
    :class="dynamicWrapperClass"
    :style="dynamicWrapperStyle"
  >
    <iframe
      v-if="isContentVisible"
      :src="videoSource"
      v-bind="iframeAttrs"
      class="a-video__iframe"
    />
  </div>
</template>

<script>
import mixins from '@/utils/mixins'
import { COOKIE_ENTITY_TYPE } from 'enums/oneTrust'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { createOneTrustHandlerInstance } from 'enums/oneTrust/one-trust-handler'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import * as types from '@/store/mutation-types'

const VISIBILITY_OFFSET = '100px'

export default {
  name: 'AVideo',
  components: {
    AmpYoutubeIframe: () => import('shared/AmpYoutubeIframe/index.vue')
  },
  mixins: [mixins.urlFormatters],
  props: {
    video: propValidator([PROP_TYPES.OBJECT], false),
    frameborder: propValidator(
      [PROP_TYPES.STRING, PROP_TYPES.NUMBER],
      false,
      '0'
    ),
    allow: propValidator(
      [PROP_TYPES.STRING],
      false,
      'autoplay; encrypted-media'
    ),
    src: propValidator([PROP_TYPES.STRING], false),
    aspectRatio: propValidator(
      [PROP_TYPES.STRING, PROP_TYPES.NUMBER],
      false,
      null
    ),
    rounded: propValidator([PROP_TYPES.BOOLEAN], false, false)
  },
  data() {
    return {
      sandbox:
        'allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox',
      isContentVisible: false,
      visibilityOptions: {
        callback: this.checkVideoVisibility,
        intersection: {
          rootMargin: VISIBILITY_OFFSET
        },
        once: true
      },
      iframeId: this.$helper.guid()
    }
  },
  computed: {
    ...mapGetters({
      isYoutubeApiLoaded: 'isYoutubeApiLoaded'
    }),
    isYoutubeAmpIframeVisible() {
      return (
        this.$helper.isAmpPage(this.$route.name) &&
        this.isYoutubeUrl(this.videoSource)
      )
    },
    iframeAttrs() {
      return {
        frameborder: this.frameborder,
        allow: this.allow,
        allowfullscreen: true,
        sandbox: this.sandbox,
        scrolling: 'yes',
        id: this.iframeId
      }
    },
    remappedVideo() {
      return this.$helper.processResponse(this.video)
    },
    videoSource() {
      if (!this.remappedVideo) return null

      return this.src
        ? this.convertYoutubeUrlToEmbedUrl(this.src)
        : this.$helper.formatYoutubeIdToEmbedUrl(this.remappedVideo.externalId)
    },
    dynamicWrapperStyle() {
      if (!this.aspectRatio) {
        return {}
      }
      const heightPadding = Math.round((100 * 100) / this.aspectRatio) / 100
      return {
        height: 0,
        paddingBottom: `${heightPadding}%`
      }
    },
    dynamicWrapperClass() {
      return {
        'rounded-corners': this.rounded
      }
    }
  },
  methods: {
    ...mapActions({
      registerHandlerThatRequiresConsent:
        'one-trust/registerHandlerThatRequiresConsent'
    }),
    ...mapMutations({
      setYoutubeApiLoadStatus: types.SET_YOUTUBE_API_LOAD_STATUS
    }),
    displayVideo() {
      this.isContentVisible = true
    },
    checkVideoVisibility(visibility) {
      if (!visibility || this.isContentVisible) return

      this.registerHandlerThatRequiresConsent(
        createOneTrustHandlerInstance({
          handler: this.displayVideo,
          entityType: COOKIE_ENTITY_TYPE.YOUTUBE
        })
      )
    }
  }
}
</script>

<style lang="scss">
.a-video__wrapper {
  width: 100%; /* default value for AVideo component, can be changed by setting class to wrapper with defined width */
  position: relative;
  overflow: hidden;
  background: $c--black;

  .a-video__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
