import { MIGRATED_CATEGORY_SLUG, SESSION_WRAPS } from 'enums/fl/fl-categories'

export const DEFAULT_AUTHOR_LOGO = 'author-default-logo.svg'

export const FL_ARTICLE_DEFAULT_IMAGE = {
  GENERAL: 'fl-article-default-general_original.jpg',
  LATEST_NEWS: 'fl-article-default-latest-news_original.jpg',
  SESSION_WRAP: 'fl-article-default-session-wrap_original.jpg',
  CENTRAL_BANKS: 'fl-article-default-central-banks_original.jpg',
  CRYPTO: 'fl-article-default-crypto_original.jpg',
  FOREX_ORDERS: 'fl-article-default-forex-orders_original.jpg',
  TECHNICAL_ANALYSIS: 'fl-article-default-technical-analysis_original.jpg'
}

export const FL_DEFAULT_IMAGE_BY_CATEGORY = {
  [MIGRATED_CATEGORY_SLUG.NEWS]: FL_ARTICLE_DEFAULT_IMAGE.LATEST_NEWS,
  [MIGRATED_CATEGORY_SLUG.CRYPTOCURRENCY]: FL_ARTICLE_DEFAULT_IMAGE.CRYPTO,
  [MIGRATED_CATEGORY_SLUG.CENTRAL_BANKS]:
    FL_ARTICLE_DEFAULT_IMAGE.CENTRAL_BANKS,
  [MIGRATED_CATEGORY_SLUG.ORDERS]: FL_ARTICLE_DEFAULT_IMAGE.FOREX_ORDERS,
  [MIGRATED_CATEGORY_SLUG.TECHNICAL_ANALYSIS]:
    FL_ARTICLE_DEFAULT_IMAGE.TECHNICAL_ANALYSIS,
  [SESSION_WRAPS]: FL_ARTICLE_DEFAULT_IMAGE.SESSION_WRAP
}
